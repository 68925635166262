<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ t('GENERAL.SELECT_DEVICE') }}</h4>
        <hr />
        <form class="row g-3 p-3">
          <div class="col-sm-10 overflow-auto status-list">
            <div class="col d-flex" v-for="(device, index) of accountDeviceList" :key="index">
              <SingleCheckBox v-if="device.deviceSerialNumber !== selectDeviceSerialNumber" class="ml-auto" :isDisable="CheckDeviceExpired(device)" :label="ShowDeviceName(device)" :id="device.deviceSerialNumber"
                @change="onDeviceCheckBoxChanged" />
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onCancelClickEvent">{{ t('GENERAL.CANCEL') }}</button>
          <button type="button" class="btn btn-primary" :disabled="deviceList.length === 0" @click="onSubmitClickEvent">{{ t('GENERAL.OK') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import SingleCheckBox from '../../Common/Tools/SingleCheckBox.vue'
export default {
  components: {
    SingleCheckBox
  },
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useStore()

    const deviceList = ref([])
    const selectDeviceSerialNumber = ref()

    const showModal = ref(false)
    const accountDeviceList = computed(() => store.getters['clientAccount/accountDeviceList'])

    function ShowDeviceName (deviceData) {
      let DeviceName = deviceData.deviceSerialNumber
      if (deviceData.carName !== deviceData.deviceSerialNumber.toString()) {
        DeviceName = DeviceName + '(' + deviceData.carName + ')'
      }
      if (CheckDeviceExpired(deviceData)) {
        DeviceName = DeviceName + ' - ' + t('GENERAL.SERVICE_TYPE[1]')
      }
      return DeviceName
    }

    function CheckDeviceExpired (deviceData) {
      let checkIsExpired = false
      if (deviceData) {
        const nowTimeStamp = new Date().getTime() * 1000
        checkIsExpired = nowTimeStamp > deviceData.serviceExpiredDate
      }
      return checkIsExpired
    }

    function showModel (deviceSerialNumber) {
      selectDeviceSerialNumber.value = deviceSerialNumber
      deviceList.value = []
      showModal.value = true
    }

    function onModalCancel () {
      showModal.value = false
    }

    function onModalSubmit () {
      emit('modalCallback', deviceList)
      showModal.value = false
    }

    function onDeviceCheckBoxChanged (selectData) {
      const { id, checked } = selectData
      if (checked) {
        deviceList.value.push(id)
      } else {
        deviceList.value = deviceList.value.filter(item => item !== id)
      }
    }

    return {
      t,
      showModal,
      deviceList,
      accountDeviceList,
      selectDeviceSerialNumber,
      showModel,
      ShowDeviceName,
      CheckDeviceExpired,
      onModalCancel,
      onModalSubmit,
      onDeviceCheckBoxChanged
    }
  },
  methods: {
    onCancelClickEvent () {
      this.onModalCancel()
    },
    onSubmitClickEvent () {
      this.onModalSubmit()
    }
  }
}
</script>
