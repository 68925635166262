import { request } from '../../../api/axios'

const state = {
  watchDeviecList: [],
  selectWatchList: [],
  selectWatchElectronicFenceList: [],
  selectWatchMarkerPointList: {},
  selectWatchIndex: -1,
  selctFilterBaseStation: false,
  isShowMarkPoint: false
}

const getters = {
  getShowMarkPoint (state) {
    return state.isShowMarkPoint
  },
  getSelectFilterBS (state) {
    return state.selctFilterBaseStation
  },
  getWatchDeviceList (state) {
    return state.watchDeviecList
  },
  getSelectWatchList (state) {
    return state.selectWatchList
  },
  selectWatchIndex (state) {
    return state.selectWatchIndex
  },
  selectWatchElectronicFenceList (state) {
    return state.selectWatchElectronicFenceList
  },
  selectWatchMarkerPointList (state) {
    const list = []
    Object.values(state.selectWatchMarkerPointList).forEach(arrList => {
      arrList.forEach(markPointData => {
        list.push(markPointData)
      })
    })
    return list
  },
  getWatchDeviceDataByNumber: (state) => (number) => {
    console.log('getWatchDeviceDataByNumber:', number)
    return state.watchDeviecList.find(device => device.deviceSerialNumber === number)
  },
  getWatchDeviceMarkPointListByNumber: (state) => (number) => {
    return state.selectWatchMarkerPointList[number] ?? []
  }
}

const mutations = {
  setShowMarkPoint (state, isShow) {
    state.isShowMarkPoint = isShow
  },
  setSelectFilterBS (state, isSelect) {
    state.selctFilterBaseStation = isSelect
  },
  setSelectWatchDeviceAddress (state, data) {
    state.selectWatchList.forEach(item => {
      if (item.deviceId === data.deviceId) {
        if (state.selctFilterBaseStation) {
          item.addressGpsOnly = data.address
        } else {
          item.address = data.address
        }
      }
    })
  },
  clearSelectWatchList (state) {
    state.selectWatchList.forEach(item => {
      item.isSelect = false
      if (state.selectWatchMarkerPointList[item.deviceSerialNumber]) {
        delete state.selectWatchMarkerPointList[item.deviceSerialNumber]
      }
    })
    state.selectWatchList.length = 0
  },
  setSelectWatchIndex (state, index) {
    // if (state.selectWatchIndex !== -1 && state.selectWatchList[state.selectWatchIndex]) {
    //   delete state.selectWatchList[state.selectWatchIndex].address
    // }
    // if (state.selectWatchIndex !== -1 && state.selectWatchList[state.selectWatchIndex]) {
    //   delete state.selectWatchList[state.selectWatchIndex].addressGpsOnly
    // }
    state.selectWatchIndex = index
  },
  addSelectWatchList (state, data) {
    if (state.selectWatchList.findIndex(item => item.deviceId === data.deviceId) === -1) {
      data.isSelect = true
      state.selectWatchList.push(data)
    }
  },
  removeSelectWatchList (state, data) {
    const index = state.selectWatchList.findIndex(item => item.deviceId === data.deviceId)
    if (index > -1) {
      data.isSelect = false
      state.selectWatchList.splice(index, 1)
      if (state.selectWatchIndex === index) {
        state.selectWatchIndex = -1
      } else if (state.selectWatchIndex > index) {
        state.selectWatchIndex -= 1
      }
    }
    if (state.selectWatchMarkerPointList[data.deviceSerialNumber]) {
      delete state.selectWatchMarkerPointList[data.deviceSerialNumber]
    }
  },
  updateItemDevice (state, item) {
    const index = state.watchDeviecList.findIndex(element => {
      return element.deviceId === item.deviceId
    })
    if (index > -1) {
      Object.assign(state.watchDeviecList[index], item)
    }
    const selectIndex = state.selectWatchList.findIndex(element => {
      return element.deviceId === item.deviceId
    })
    if (selectIndex > -1) {
      if (state.selectWatchList[selectIndex].lastUpdateLatitude !== item.lastUpdateLatitude ||
        state.selectWatchList[selectIndex].lastUpdateLongitude !== item.lastUpdateLongitude ||
        state.selectWatchList[selectIndex].lastUpdateLatitudeGpsOnly !== item.lastUpdateLatitudeGpsOnly ||
        state.selectWatchList[selectIndex].lastUpdateLongitudeGpsOnly !== item.lastUpdateLongitudeGpsOnly
      ) {
        delete state.selectWatchList[selectIndex].address
        delete state.selectWatchList[selectIndex].addressGpsOnly
      }
    }
  },
  addItemDevice (state, item) {
    const index = state.watchDeviecList.find(element => {
      return element.deviceId === item.deviceId
    })
    if (!index) {
      item.isSelect = true
      state.watchDeviecList.push(item)

      state.selectWatchList.forEach(device => {
        device.isSelect = false
        if (state.selectWatchMarkerPointList[device.deviceSerialNumber]) {
          delete state.selectWatchMarkerPointList[device.deviceSerialNumber]
        }
      })
      state.selectWatchList.length = 0
      if (item.isSelect) {
        state.selectWatchList.push(item)
      }
    }
  },
  removeItemDevice (state, item) {
    const index = state.watchDeviecList.indexOf(item)
    if (index > -1) {
      state.watchDeviecList.splice(index, 1)
      const selectIndex = state.selectWatchList.indexOf(item)
      if (selectIndex > -1) {
        state.selectWatchList.splice(selectIndex, 1)
      }
      if (state.selectWatchMarkerPointList[item.deviceSerialNumber]) {
        delete state.selectWatchMarkerPointList[item.deviceSerialNumber]
      }
    }
  },
  setSelectChangeEvent (state, selectItem) {
    const item = state.watchDeviecList.find(element => {
      return element.deviceId === selectItem.deviceId
    })
    if (item) {
      item.select = !item.select
    }
  },
  setElectronicFenceList (state, data) {
    data.rows.forEach(element => {
      if (state.selectWatchElectronicFenceList.findIndex(fence => fence.id === element.id) === -1) {
        const content = Object.assign({}, element)
        content.isEnterWarning = element.isEnterWarning === 1
        content.isExitWarning = element.isExitWarning === 1
        state.selectWatchElectronicFenceList.push(content)
      }
    })
  },
  setMarkerPointList (state, data) {
    data.rows.forEach(element => {
      if (!state.selectWatchMarkerPointList[element.deviceSerialNumber]) {
        state.selectWatchMarkerPointList[element.deviceSerialNumber] = []
      }
      if (state.selectWatchMarkerPointList[element.deviceSerialNumber].findIndex(marker => element.id === marker.id) === -1) {
        state.selectWatchMarkerPointList[element.deviceSerialNumber].push(element)
      }
    })
  },
  removeElectronicFenceData (state, fenceId) {
    const index = state.selectWatchElectronicFenceList.findIndex(fence => fence.id === fenceId)
    if (index > -1) {
      state.selectWatchElectronicFenceList.splice(index, 1)
    }
  },
  clearWatchElectronicFenceList (state) {
    state.selectWatchElectronicFenceList.length = 0
  },
  clearWarchMrkerPointList (state) {
    state.selectWatchMarkerPointList = {}
  }
}

const actions = {
  async getCarTrackerLogs ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 0
    }
    params = { ...params, ...data }
    return request('/api/getCarTrackerLogs', JSON.stringify(params))
  },
  async getOfflineReports ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 0
    }
    params = { ...params, ...data }
    return request('/api/getOfflineReports', JSON.stringify(params))
  },
  async getElectronicFence ({ rootState }, deviceNumber) {
    const params = {
      userToken: rootState.userToken,
      deviceSerialNumber: deviceNumber
    }
    return request('/api/getFences', JSON.stringify(params))
  },
  async getMarkerPointList ({ rootState }, deviceNumber) {
    const params = {
      userToken: rootState.userToken,
      deviceSerialNumber: deviceNumber
    }
    return request('/api/getMarkers', JSON.stringify(params))
  },
  async addMarkerPoint ({ rootState }, markerData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...markerData }
    return request('/api/addMarker', JSON.stringify(params))
  },
  async removeMarkerPoint ({ rootState }, markerId) {
    const params = {
      userToken: rootState.userToken,
      id: markerId
    }
    return request('/api/deleteMarker', JSON.stringify(params))
  },
  async editorMarkerPoint ({ rootState }, markerData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...markerData }
    return request('/api/editMarker', JSON.stringify(params))
  },
  async addFence ({ rootState }, fenceData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...fenceData }
    return request('/api/addFence', JSON.stringify(params))
  },
  async editorFence ({ rootState }, fenceData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...fenceData }
    return request('/api/editFence', JSON.stringify(params))
  },
  async removeFence ({ rootState }, fenceId) {
    const params = {
      userToken: rootState.userToken,
      id: fenceId
    }
    return request('/api/deleteFence', JSON.stringify(params))
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
