export const locale = {
  GENERAL: {
    TITLE: '昇豪科技GPS查車平台',
    LOGOUT: 'Sign out',
    SEARCH_TITLE: 'Search',
    OPTION_DEFAULT_SELECT: '--- please choose ---',
    ADD: 'Add',
    EDITOR: 'Edit',
    SAVE: 'Save',
    CLOSE: 'Close',
    VIEW: 'View',
    CANCEL: 'Cancel',
    DELETE: 'Del',
    COPY: 'Copy',
    OK: 'OK',
    PAYMENT: 'Payment',
    IMPORT_EDIT: 'Batch Edit',
    IMPORT_ADD: 'Batch Add',
    CONTENT: 'content',
    ALLOCATE: 'Allocate',
    YES: 'Yes',
    NO: 'No',
    RESET: 'Reset',
    SELECT_DEVICE: 'Select Deivce Serial Number',
    SERVICE_TYPE: ['Normal', 'Expired', 'Stop', 'paymentStop'],
    SETTING_STATUS: ['Disable', 'Enable'],
    SERVICE_PLAN_STATUS: ['Disable', 'Enable'],
    VIP_PLAN_SHOW: 'Show',
    VIP_PLAN_HIDE: 'Hidden',
    MANAGER_DEVICE: 'Management Terminal',
    PASSWORD_HOLDER: 'Please enter a new password',
    CONFIRM_PASSWORD_HOLDER: 'Please enter the password again',
    DATE_START: 'Start',
    DATE_END: 'Finish',
    SEARCH: 'Inquire',
    EXPORT: 'Export',
    ALL: 'All',
    RENEW: 'Renewal',
    MODIFY: '校正',
    STOP_SERVICE: 'Stop',
    MORE: 'More',
    HIDDEN: 'Hidden',
    SEND: 'Send',
    DESCRIPT: 'Illustrate',
    FILTER_BASE_STATION: 'Filter BS',
    DAY: 'd ',
    HOUR: 'h ',
    MIN: 'm ',
    SEC: 's ',
    DAYS: 'd ',
    HOURS: 'h ',
    MINS: 'm ',
    SECS: 's ',
    TRACKING: 'Track',
    FEEBACK: 'Problem report',
    HELP: 'Help',
    MESSAGE_LIST: 'Message list',
    AUTO_LOGOUT: 'Auto logout countdown',
    DISCOUNT: 'Discount',
    REFRESH: 'Refresh',
    SELECT_ALL: 'Select All',
    REVERSE_SELECT: 'Invert',
    UNSELECT_ALL: 'Clear',
    DRAW_MAP: 'Draw',
    FINISH: 'Finish',
    ADDRESS_TO_GPS: 'Covert GPS',
    ANDROID_USER: 'Android',
    APPLE_USER: 'Apple',
    CUSTOMER: 'Customer service Info',
    MODAL_PROGRESS_HINT: 'update in progress...',
    LOADING: 'Loading...',
    NAVIGATE: 'Navigate',
    PANORAMA: 'Panorama',
    RESET_PASSWORD: 'Reset Password',
    DOWNLOAD_FORMAT: 'download',
    REMEMBER_ACCOUNT: 'save password',
    EXTRA_MAP_TIMES: '(Map8)',
    ADD_VALUE: 'Go to top up',
    GET_ADDRESS: 'Get Address',
    SEARCH_TIME: 'Timezone',
    GPSSIGNAL: 'GPS signal:',
    GPSSIGNAL_VALUE: ['Weak', 'Medium', 'Strong'],
    MESSAGE_TITLE: ['Date', 'Category', 'Content', 'Action'],
    MESSAGE_TYPE: ['Announcement', 'Message'],
    RETURN: 'BACK',
    FREQ_CONTENT: ['Normal', 'Fast']
  },
  LANGUAGE: {
    TITLE: 'Language',
    SELECT: ['Traditional Chinese', 'English']
  },
  ERROR_MSG: {
    REQUIRED: 'Field must not be empty!',
    MARKER_ADDRESS_TO_GPS_EMPTY: 'The target and address cannot be null'
  },
  MODAL: {
    ERROR_TITLE: 'Error message',
    NOADDRESS_TITLE: 'Address Service Description',
    NOADDRESS_HINT: 'The system provides 100,000 calls per day, with a free call address quota for all users, which will be reset to zero at 9:00 every morning and recalculated. \r\n\r\nAfter the call address quota is used up, you can still refer to the map and street view function to check the location of the vehicle without affecting the use. \r\n\r\n(The fee is paid to Tuba Tu Capital Company, not charged by Tracker. Since we do not charge platform fees, some users are slightly burdened, please forgive me)',
    MULTIMONITOR_NOADDRESS_HINT: 'Due to limited free resources, if you want to use multi-vehicle monitoring and address display functions, please add value and call Tuba address quota to use it. \r\n\r\n(This fee is paid by Tubatu Funding Company, not by Tracking King. Since we do not charge platform fees, some users are a little burdened, please forgive me) ',
    SERVICE_EXPIRE_TITLE: 'Telecom traffic expiration description',
    SERVICE_EXPIRED: '(Telecom traffic has expired)',
    SERVICE_EXPIRED_HINT: 'Intimate reminder:\r\n\r\nYour device [{0}] telecom traffic has expired, please go to [Service Expired] to renew the telecom traffic immediately,\r\n\r\nIn order not to affect your normal use. \r\n\r\nThank you for GPS King!',
    MESSAGE_FINANCE_CONTENT: 'Your {0} {1}, {2} jobs were successful. ',
    HISTORY_NO_DATA: 'No trace data of device【{0}】at this time period found!'
  },
  ADMIN_LOGIN: {
    TITLE: 'Platform management login page',
    ACCOUNT: 'Account:',
    ACCOUNT_HINT: 'Please enter the management account',
    ACCOUNT_CLIENT_HINT: 'Please enter the account/terminal number',
    PASSWORD: 'Password:',
    PASSWORD_HINT: 'Please enter a password',
    BTN_LOGIN: 'Login',
    FORGET_PASSWORD: 'Forgot password',
    EMAIL_HINT: 'Please enter account Email',
    FORGET_PASSWORD_HINT: 'If Email is not set, please contact customer service'
  },
  TOP_TABS: {
    ACCOUNT: 'Account',
    PERMISSION: 'Permission',
    DEVICE: 'Terminal',
    FLEET: 'Fleet',
    CUSTOMER: 'Customer',
    PLAN: 'Renewal bonus',
    VIP_PLAN: 'VIP',
    STATISTICS: 'Statistics',
    REPORT: 'Report',
    FINANCE: 'Financial',
    ANNOUNCEMENT: 'Announcement',
    SYSTEM_LOG: 'Systemlog',
    SETTING: 'Settings',
    MONITOR: 'Monitor',
    MULTI_MONITOR: 'Multi-Monitor',
    REMOTE: 'remote',
    WARNING: 'Warning',
    HISTORY: 'History',
    SERVICE_EXPIRE: 'Service',
    MILEAGE: 'Mileage',
    TELECOM_SERVICE: 'Telecom service'
  },
  SIDE_MENU: {
    VIP_PLAN: ['Renewal Service Management', 'VIP Premium Service Management', 'Discount Coupon Management'],
    ANNOUNCEMENT: ['Announcement list', 'Message list']
  },
  ADMIN_ACCOUNT: {
    TABLES: ['#', 'Account Name', 'Nickname', 'Permission', 'Status', 'Last Login Time', 'Action'],
    MODAL_TITLE: 'System account',
    MODAL_CONTENT: ['account name', 'nickname', 'password', 'password confirmation', 'authority', 'status'],
    NEVER_LOGIN: 'Not yet logged in'
  },
  ADMIN_PERMISSION: {
    TABLES: ['#', 'authority name', 'action'],
    MODAL_TITLE: 'Permission item',
    MODAL_CONTENT: ['authority name', 'authority function']
  },
  ADMIN_DEVICE: {
    SEARCH_NUMBER: 'Terminal number',
    SEARCH_ZONE: 'Terminal zone',
    TABLES: ['#', 'number', 'fleet', 'plan', 'status', 'Tuba address times', 'service expiration time', 'establishment time', 'action'],
    PLAN: ['No plan', 'plan A', 'plan B', 'plan C', 'plan D', 'plan E', 'plan F', 'plan G', 'plan H', 'plan I', 'Plan J'],
    NONE_PLAN: '',
    STATUS: ['Not allocated', 'Allocated'],
    MODAL_VIEW_TITLE: 'Terminal information',
    MODAL_ADD_TITLE: 'Add terminal number',
    MODAL_ALLOCATE_TITLE: 'Single stroke transfer',
    MODAL_VIEW_CONTENT: ['terminal number', 'owner\'s name', 'vehicle name', 'SIM card number', 'mobile phone number', 'password', 'car power', 'firmware version', 'ESIM ID', 'add value service', 'service status', 'Map8 address times', 'Email'],
    MODAL_ADD_CONTENT: ['Starting terminal number', 'Number of new transactions', 'Default scheme'],
    MODAL_ALLOCATE_CONTENT: ['terminal number', 'service expiration time', 'plan', 'transfer fleet'],
    MODAL_IMPORT_CHECK_HINT: 'Check the data',
    MODAL_ALLOCATE_HINT: 'Please note that the terminal is already in use!!!'
  },
  ADMIN_FLEET: {
    TABLES: ['#', 'Account', 'Name', 'Phone', 'Email', 'Number of vehicles', 'Last login time', 'Action'],
    SUB_TABLES: ['#', 'Account', 'Sub-team name', 'Number of vehicles', 'Action'],
    MODAL_TITLE: 'Fleet account',
    MODAL_MAIN_FLEET: 'Main fleet account',
    MODAL_FLEET_CONTENT: ['Account', 'Name', 'Contact', 'Phone', 'Address', 'Email', 'Password', 'Permission'],
    MODAL_SUB_FLEET_TITLE: 'Sub-team account',
    MODAL_SUB_FLEET_CONTENT: ['Account', 'Password', 'Sub-team name', 'Permission'],
    SUB_PERMISSION: ['Edit vehicle information', 'Fleet management', 'Send command'],
    AUTO_PASSWORD: 'The system is automatically created',
    RESET_PASSWORD: 'Email reset',
    FLEET_SUB_LIST: 'Sub fleet list',
    FLEET_DEVICE_LIST: 'Terminal list'
  },
  ADMIN_SYSTEM_LOG: {
    TABLES: ['#', 'time', 'account', 'action', 'status'],
    ACTION_STATUS: ['', 'success', 'insufficient permissions', 'failure']
  },
  ADMIN_PLAN: {
    TABLES: ['#', 'terminal number', 'vehicle name', 'affiliated fleet', 'status', 'service expiration time', 'bonus expiration time', 'action', 'Times of top-up'],
    MODAL_TITLE: 'Edit service status',
    MODAL_MODIFY_SERVICE_TYPE: '校正服務項目',
    EXPIRED_DAY: 'Service expiration date',
    MODIFY_SERVICE_EXPIRED: 'Calibration Service Due Date',
    VIP_SERVICE_DAY: 'Add-on expiration date',
    EXPIRED: 'Service renewal (days)',
    VALUE_SERVICE: 'Value-added service (days)',
    SEARCH_HOLDER: 'Please enter terminal number/vehicle name',
    EXTRA_ADDRESS_TIMES: 'Number of value-added addresses',
    IMPORT_MODIFY_DATA: 'batch calibration',
    EXPIRED_NEXT_YEAR: '+年',
    OVER_ONE_YEAR_WARNING_HINT: '您所選擇的到期日已超出該設備服務到期日一年，請確認是否有誤!!!'
  },
  ADMIN_VIP_PLAN: {
    SERVICE_TABLES: ['#', 'Plan', 'Item', 'Price', 'Time (day)', 'Additional setting fee for overtime', 'Status', 'Action', 'top-up times'],
    COUPON_TABLES: ['serial number', 'discount coupon number', 'rebate fee', 'status', 'order number', 'terminal number', 'use time', 'build time', 'action'],
    COUPON_STATUS: ['Not sent', 'Sent', 'Used'],
    COUPON_ACTION_BUTTON: ['Send', 'Use', 'Used'],
    MODAL_SERVICE_TITLE: 'Renewal project',
    MODAL_VIP_PLAN_TITLE: 'VIP value-added service project',
    MODAL_COUPON_TITLE: 'Add discount coupon',
    MODAL_COUPON_USE_TITLE: 'Use discount coupon',
    MODAL_COUPON_CONTENT: ['Number of new transactions', 'Rebate amount'],
    MODAL_SERVICE_CONTENT: ['Project name', 'Amount', 'Add-on days', 'Program', 'Overtime setting fee', 'Overtime days', 'Description', 'Status', 'Add-on Tuba Times', 'Accounting item'],
    MODAL_CONFIRM_CONTENT: 'Send {0} coupon?'
  },
  ADMIN_ANNOUNCEMENT: {
    ANNOUNCEMENT_TABLES: ['#', 'sender', 'content', 'send time', 'build time', 'action'],
    MESSAGE_TABLES: ['#', 'sender', 'content', 'receiver', 'send time', 'build time', 'action'],
    MODAL_ANNOUNCEMENT_CONTENT: ['send time', 'send content'],
    MODAL_MESSAGE_RECIVER_TABLES: ['#', 'Receive object', 'Whether it contains a vehicle', 'Action'],
    MODAL_SEARCH_OPTION: ['Fleet', 'Number'],
    MODAL_SHOW_TITLE: ['Announcement content', 'Message content']
  },
  ADMIN_REPORT: {
    REPORT_SEARCH_OPTIONS: ['Account Name', 'Contact', 'Contact'],
    REPORT_TABLES: ['#', 'Account name', 'Contact', 'Contact information', 'Question category', 'Status', 'Time', 'Action'],
    PROCESS_STATUS: ['Not processed', 'Contacted', 'Case closed'],
    MODAL_TITLE: 'Problem report content',
    REPORT_TYPE: ['Operation problem', 'Car problem', 'Other'],
    REPORT_METHOD_PLACEHOLDER: 'Please enter a contact number or Email'
  },
  ADMIN_FINANCE: {
    TABLES: ['#', '訂單日期', '終端帳號', '服務名稱', '付款金額', '藍新金流交易序號', '訂單狀態', '付款時間', '付款方式', '付款註記', '發票類型', '發票號碼', '發票註記', '發票時間', '動作'],
    SEARCH_OPTIONS: ['All', 'order number', 'terminal account number', 'time interval'],
    SEARCH_PAYMENT_OPTIONS: ['尚未支付', '支付完成', '支付失敗', '訂單退款'],
    PAYMENT_STATUS: ['尚未支付', '授權完成', '授權失敗', '已取消授權', '請款申請中', '請款處理中', '已請款', '退款申請中', '退款處理中', '已退款'],
    ORDER_STATUS: ['', '等待支付', '已支付交付中', '已完成', '支付失敗已關閉', '取消作業申請中', '退還商品中', '退還商品完成', '退款作業中', '退款完成', '退票作業中', '已取消'],
    INVOICE_TYPE: ['personal', 'company', 'donation'],
    INVOICE_NOTE: ['Vehicle barcode', 'Uniform number', 'Donation code'],
    PLAN_TYPE: ['', '續約服務', '加值服務'],
    EXPORT_NAME: '昇豪對帳資訊',
    EXPORT_TITLE: ['訂單日期', '訂單號碼', '機器號碼', '購買項目', '購買方案', '開始(日期/次)', '結束(日期/次)', '購買金額', '優惠抵扣金額', '實際付款金額', '付款方式', '付款日期', '發票號碼', '發票日期'],
    PAYMENT_NOTE: ['後四碼'],
    ACTION_TITLE: ['退款', '作廢', '取消訂單'],
    MODAL_TITLE: ['交易取消/退款', '發票作廢/折讓'],
    MODAL_PAYMENT_TYPE: ['『人工手動加值』', '『藍新金流交易序號』'],
    MODAL_REFUND_CONTENT: '確認是否要將{0} \r\n【{1}】\r\n交易金額【{2}】 \r\n進行取消 或 退款 的動作?',
    MODAL_ABORT_CONTENT: '確認是否要將\r\n發票號碼 【{0}】\r\n進行作廢 或 折讓 的動作?',
    PAYMENT_TYPE: ['全部', '信用卡', '人工儲值', '超商支付'],
    RECORD_DATA_TYPE: '查詢項目'
  },
  ADMIN_STATISTICS: {
    SIDE_MENU: ['overview', 'mileage', 'alarm', 'speeding', 'offline', 'running'],
    SIDE_SUB_MENU: ['Single day ', 'Single month ', 'Day interval '],
    SIDE_SUB_EXTRA: ['Offline terminal statistics', 'Online status statistics'],
    OVERSPEED: 'speed limit',
    OFFLINE_END_TIME: 'deadline',
    OFFLINE_DAYS: 'offline time',
    STATISTICS_SLEEPING_DEVICE: 'Statistics of dormant devices'
  },
  ADMIN_SETTING: {
    DEFAULT_SETTING_TITLE: 'Basic settings',
    MAP_SETTING_TITLE: 'Map settings',
    DEFAULT_SETTINGS: ['mark point conversion function', 'APP vehicle address function', 'speeding report statistics', 'offline report statistics', 'discount roll function', 'screen update frequency (seconds)'],
    MAP_SETTINGS: ['(Google) latitude and longitude query address', '(Google) address query latitude and longitude', 'Google map capital limit'],
    MAP_SUB_SETTING: ['Daily Total Cap', 'Monthly Cap', 'VIP Daily Cap', 'VIP Monthly Cap']
  },
  MESSAGE: {
    DELETE_SUB_FLEET: 'Do you want to delete the sub-team account: ',
    DELETE_MARKER_POINT: 'Do you want to delete the [{1}] marker of [{0}]?',
    DELETE_ELECTRONIC_FENCE: 'Do you want to delete the [{1}] electronic fence of [{0}]?',
    DELETE_MILEAGE_MAINTANCE: 'Do you want to delete the [{1}] maintenance item for [{0}]?',
    STOP_DEVICE_STATUS: 'Do you want to shut down terminal accounts such as [{0}]?',
    ERROR_TIME_PICKER: 'The content format of the time field is incorrect, please enter the correct format (hh:mm)',
    ERROR_PASSWORD: 'Please confirm whether the password is incorrect',
    MESSAGE_SEARCH_DATA_EMPTY: 'No data found in search terms, please search again'
  },
  TABLES: {
    ALL: ['#', 'number', 'mileage(km)', 'overspeed', 'warning', 'offline'],
    MILEAGE: ['#', 'number', 'Date', 'Period', 'Mileage (km)', 'Track'],
    WARNING: ['#', 'number', 'Date', 'Time', 'Longitude', 'Latitude', 'Warning type', 'Warning times', 'Track'],
    OVERSPEED: ['#', 'number', 'Date', 'Period', 'overspeed', 'track'],
    OFFLINE: ['#', 'number', 'Date', 'start time', 'end time', 'Duration', 'Track'],
    ALERT: ['number', 'GPS time', 'positioning method', 'speed', 'latitude & longtiude', 'status', 'message', 'times', 'number'],
    TRACKER: ['#', 'GPS time', 'positioning method', 'address', 'marker name', 'latitude and longitude', 'speed', 'vehicle status', 'warning message', 'parking time'],
    PARKING: ['#', 'GPS time', 'positioning method', 'address', 'parking time', 'vehicle status', 'warning message'],
    HISTORY_WARNING: ['#', 'GPS time', 'positioning method', 'address', 'warning message', 'vehicle status', 'speed'],
    OFFLINE_DEVICE: ['#', '車牌號', '終端號碼', 'SIM卡號', '車主電話', '附屬車隊', '服務狀態', '最後上線時間', '離線時長', '車輛狀態'],
    ONLINE_STATUS_TYPE: ['在線', '未使用', '休眠', '離線小於12小時', '離線大於12小時至90天', '未上線達90天', '未上線達180天']
  },
  HISTORY: {
    CONTROL_TOTAL: 'Total',
    CONTROL_POS: 'Position',
    CONTROL_MILEAGE: 'Total Mileage',
    CONTROL_STEP: 'Playback progress',
    CONTROL_SPEED: 'Playback speed',
    CONTROL_START: 'Start playback',
    CONTROL_STOP: 'Stop playback',
    CONTROL_SHOW_PARKING: 'Show parking spots',
    TABLES: ['Track report', 'Parking report', 'Alarm report'],
    CONTROL_HINT: 'Only base station positioning has trajectory information, please uncheck [Filter BS] to view trajectory information!'
  },
  ALERT: {
    REMOVE_SINGLE: 'Remove the alarm',
    REMOVE_ALL: 'Remove all',
    ALERT_TITLE: 'You have [{0}] vehicles alarmed, and a total of [{1}] alarms occurred',
    ALERT_TYPE: ['', 'Vibration alarm']
  },
  REMOTE: {
    TABS: ['Query', 'Setting', 'Remote', 'Request'],
    TITLE: 'Parameter',
    VALUE: 'Value',
    CONTROL: ['Reboot device', 'Turn on vibration alarm', 'Turn off vibration alarm', 'Turn on audio alarm', 'Turn off audio alarm'],
    TABLES: ['time', 'number', 'command', 'parameters', 'content', 'result'],
    LEFT_DEVICE_LIST: 'target vehicle',
    MODAL_FREQ_CONTENT: 'Please note: \r\n\r\n1. "Fast" positioning update speed will consume more power. \r\n2. Do not adjust this setting if it runs for more than 13 hours a day.'
  },
  RECORD: {
    TITLE: 'Recording',
    CONTROL: ['Turn off the recording', 'Turn on the recording'],
    REMOTE_MODEL_CONTENT: 'Sending request',
    TABLES: ['#', 'Terminal number', 'Recording time', 'Receive time', 'Action'],
    BUTTON: ['Request to upload', 'The data has expired', 'Download the file', 'The file is being uploaded'],
    MODAL_CONTENT: ['Requested {0} to confirm whether the {1} recording file exists. If it exists, please wait for the device to upload the recording file']
  },
  ADMIN_MONITOR: {
    DEVICE_TABLES: ['number', 'GPS time', 'Location type', 'speed', 'latitude and longitude', 'address', 'status', 'warning message', 'battery capacity', 'GPS signal', 'GSM signal', 'voltage', 'expiration']
  },
  MONITOR: {
    TABS: ['Vehicle List', 'Electronic Fence', 'Marked Point'],
    MARKED_POINT_TABLES: ['#', 'action number', 'name', 'coordinates', 'note', 'action'],
    ELE_FENCE: ['#', 'action number', 'name', 'enter alarm', 'leave alarm', 'type', 'note', 'action'],
    LOCATE_TYPE: ['Base Station Positioning', 'GPS Positioning'],
    DEVICE_STATUS_TYPE: ['Sleep', 'Stationary', 'Motion', 'NoSignal'],
    PROTECTED_TYPE: ['Undefended', 'Defended(vibration)', 'Defended(sound)', 'Defended(comprehensive)'],
    MODAL_ELEFENCE: 'Electronic fence object',
    MODAL_MARKER_POINT: 'marker object',
    ALARM_TYPE: 'Alarm type',
    ELE_TARGET_TYPE: ['Home', 'Company', 'Custom'],
    MODAL_MARKER_TYPE: 'Marker method',
    MARKER_POINT_TYPE: ['Address conversion GPS', 'Map click'],
    MARKER_POINT: 'marker point',
    ADDREDS_TO_GPS_HINT: 'Please enter the location address',
    DRAW_MAP_HINT: 'Please click the [Draw] button to draw on the page',
    PARKING_TIME: 'Parking: {0}',
    DRAW_ELECTONIC_FENCE: 'Draw electronic fence',
    DRAW_MARKER_POINT: 'Draw marker point',
    DRAW_TYPE_TITLE: 'Drawing method',
    DRAW_TYPE: ['Concentric circles', 'Multi-point setting'],
    COORS: ['longitude', 'latitude'],
    IS_SLEEPING: 'Sleep'
  },
  TABLE_FOOTER: {
    FIRST: 'Home',
    PREVIOUS: 'Previous page',
    NEXT: 'Next page',
    END: 'last page',
    TOTAL_PAGE: 'Total {0} pages',
    TOTAL_COUNT: 'Total {0} records',
    EXPORT: 'export'
  },
  LEFT_LIST: {
    TITLE: 'Vehicle list \n(please right click for more functions)'
  },
  SERVICE_EXPIRE: {
    TITLE: 'You have a [{0}] car service that has expired or is about to expire, please renew it immediately to avoid affecting your normal use',
    TABLES: ['#', 'Number', 'Vehicle name', 'Status', 'Service due time', 'Remaining time', 'Action'],
    RENEW: 'Renew now',
    MODAL_TITLE: 'Renewal payment information',
    MODAL_OPTION: 'Contract renewal project',
    MODAL_PRICE: 'Renewal fee',
    MODAL_COUPON: 'Coupon',
    MODAL_TOTAL: 'Total'
  },
  MAINTENANCE: {
    TABLES: ['#', 'Vehicle name', 'Current mileage (km)', 'Maintenance mileage (km)', 'Maintenance item', 'Content', 'Action']
  },
  VIP_SERVICE: {
    TABLES: ['#', 'item', 'price', 'action'],
    ADD_SERVICE: 'Immediately add value',
    MODAL_TITLE: 'VIP service payment information',
    MODAL_INVOICE: ['email', 'invoice info', 'description'],
    MODAL_INVOICE_TYPE: ['Personal', 'Company', 'Donation'],
    INVOICE_DEVICE: 'Invoice carriers',
    INVOICE_TAX: 'Uniform Invoice',
    INVOICE_EMAIL: ['same as above', 'custom'],
    PAYMENT_HINT: 'This platform does not provide paper invoices, electronic invoices will be issued by Shenghao Technology',
    EMAIL_HINT: 'Please fill in the Email address to receive the electronic invoice',
    INVOICE_DEVICE_HINT: 'Please enter a total of 8 barcodes starting with "/"',
    INVOICE_TAX_HINT: 'Please enter the 8-digit Uniform number',
    EMAIL_CLONE: 'same as above',
    SERVICE_CATEGORY: 'Category'
  },
  MILEAGE_MAINTANCE: {
    TABLES: ['#', 'Vehicle name', 'Current mileage(km)', 'Maintenance mileage(km)', 'Maintenance item', 'Content', 'Action'],
    MODAL_TITLE: 'mileage maintenance item'
  },
  COUPON: {
    IS_USED: 'The serial number has been used',
    CAN_NOT_USED: 'This serial number cannot be used',
    SERIAL_ERROR: 'The input serial number is incorrect'
  },
  CONTEXT_MENU: {
    FLEET: ['Fleet information', 'Batch command', 'Data statistics', 'Space object', 'Create sub-fleet'],
    DEVICE: ['Display track', 'Lock monitoring', 'Vehicle information', 'Historical track', 'Send command', 'Data statistics', 'Space object', 'Allocation fleet'],
    SUN_FLEET_REMOTE: ['Only the vehicles of this team', 'All vehicles of the subordinate'],
    MODAL_DEVICE_CONTENT: ['device number', 'vehicle name', 'owner\'s name', 'mobile number', 'password', 'SIM card number', 'expiration date', 'vehicle icon', 'Email'],
    REMOTE_MAIN: ['Locate now', 'Fortify and disarm', 'More commands'],
    REMOTE_SUB_MENU: ['arm', 'disarm'],
    CAR_ICON: ['car', 'linked car', 'lorry', 'motorcycle', 'excavator', 'boat', 'pedestrian']
  },
  MULTI_MONITOR: {
    CONTROL_TITLE: ['landmark display', 'satellite map', 'address highlight'],
    BATTERY: 'battery',
    ZOOM_BAR: 'Scale bar',
    LAST_UPDATE_TIME: 'Postback time',
    MULTI_MONITOR_HINT: 'Please select a vehicle on the left, up to 9 vehicles can be monitored at the same time'
  }
}
