<template>
  <input class="" type="checkbox" :id="id" :disabled="isDisable" v-model="checked" @change="$emit('change', { id: this.id, checked: this.checked })" />
  <label class="p-1">{{ label }}</label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    modelValue: { type: Boolean, default: false },
    label: { type: String },
    id: { type: Number },
    isDisable: { type: Boolean, default: false }
  },
  emits: ['change'],
  data () {
    return {
      checked: this.modelValue
    }
  }
}
</script>
