export const locale = {
  GENERAL: {
    TITLE: '昇豪科技GPS查車平台',
    LOGOUT: '登出',
    SEARCH_TITLE: '搜尋',
    OPTION_DEFAULT_SELECT: '--- 請選擇 ---',
    ADD: '新增',
    EDITOR: '編輯',
    SAVE: '儲存',
    CLOSE: '關閉',
    CANCEL: '取消',
    VIEW: '檢視',
    DELETE: '刪除',
    COPY: '複製',
    OK: '確認',
    PAYMENT: '付款',
    IMPORT_EDIT: '批量修改',
    IMPORT_ADD: '批量新增',
    CONTENT: '內容',
    ALLOCATE: '劃撥',
    YES: '是',
    NO: '否',
    RESET: '重設',
    SELECT_DEVICE: '選擇設備號碼',
    SERVICE_TYPE: ['正常', '到期', '停機', '付費停機'],
    SETTING_STATUS: ['關閉', '開啟'],
    SERVICE_PLAN_STATUS: ['未啟用', '已啟用'],
    VIP_PLAN_SHOW: '顯示',
    VIP_PLAN_HIDE: '不顯示',
    MANAGER_DEVICE: '管理終端',
    PASSWORD_HOLDER: '請輸入新密碼',
    CONFIRM_PASSWORD_HOLDER: '請再次輸入密碼',
    DATE_START: '開始',
    DATE_END: '結束',
    SEARCH: '查詢',
    EXPORT: '匯出',
    ALL: '全部',
    RENEW: '人工加值',
    MODIFY: '校正',
    STOP_SERVICE: '一鍵停機',
    MORE: '更多',
    HIDDEN: '隱藏',
    SEND: '發送',
    DESCRIPT: '說明',
    FILTER_BASE_STATION: '過濾基地台定位',
    DAY: '日',
    HOUR: '時',
    MIN: '分',
    SEC: '秒',
    DAYS: '日',
    HOURS: '時',
    MINS: '分',
    SECS: '秒',
    TRACKING: '軌跡',
    FEEBACK: '問題回報',
    MESSAGE_LIST: '訊息通知',
    HELP: '幫助',
    AUTO_LOGOUT: '自動登出倒數',
    DISCOUNT: '折扣',
    REFRESH: '刷新',
    SELECT_ALL: '全選',
    REVERSE_SELECT: '反選',
    UNSELECT_ALL: '清空',
    DRAW_MAP: '繪製',
    FINISH: '完成',
    ADDRESS_TO_GPS: '轉換GPS',
    ANDROID_USER: '安卓用戶',
    APPLE_USER: '蘋果用戶',
    CUSTOMER: '客服資訊',
    MODAL_PROGRESS_HINT: '更新進度中...',
    LOADING: '資料載入中...',
    NAVIGATE: '導航',
    PANORAMA: '街景',
    RESET_PASSWORD: '重設密碼',
    DOWNLOAD_FORMAT: '下載格式',
    REMEMBER_ACCOUNT: '記住密碼',
    EXTRA_MAP_TIMES: '(圖霸)',
    ADD_VALUE: '前往儲值',
    GET_ADDRESS: '取得地址',
    SEARCH_TIME: '時間區間',
    GPSSIGNAL: '衛星訊號：',
    GPSSIGNAL_VALUE: ['弱', '中等', '強'],
    MESSAGE_TITLE: ['日期', '類型', '內容', '動作'],
    MESSAGE_TYPE: ['公告', '訊息'],
    RETURN: '返回',
    FREQ_CONTENT: ['一般', '快']
  },
  LANGUAGE: {
    TITLE: '語系',
    SELECT: ['繁體中文', '英文']
  },
  ERROR_MSG: {
    REQUIRED: '欄位不得為空!',
    MARKER_ADDRESS_TO_GPS_EMPTY: '作用對象及地址不得為空值'
  },
  MODAL: {
    ERROR_TITLE: '錯誤訊息',
    NOADDRESS_TITLE: '轉址服務說明',
    NOADDRESS_HINT: '系統每日提供十萬筆，免費呼叫地址額度，供所有用戶使用，並於每日早上9:00歸零重新計算。\r\n\r\n當呼叫地址額度使用完後，依然可參考地圖與街景功能，查詢車輛所在位置，不影響使用。\r\n\r\n(該費用是支付圖霸圖資公司，並非追蹤王收取，由於我們不收取平台費用，故部分使用者些微負擔，懇請見諒)',
    MULTIMONITOR_NOADDRESS_HINT: '因免費資源有限，如欲使用多車監控，顯示地址功能，請加值呼叫圖霸地址額度即可使用。\r\n\r\n(該費用是支付圖霸圖資公司，並非追蹤王收取，由於我們不收取平台費用，故部分使用者些微負擔，懇請見諒) ',
    SERVICE_EXPIRE_TITLE: '電信流量到期說明',
    SERVICE_EXPIRED: '(電信流量已到期)',
    SERVICE_EXPIRED_HINT: '貼心提醒：\r\n\r\n您的裝置【{0}】電信流量已到期，請立即至【服務過期】續約電信流量，\r\n\r\n以免影響您的正常使用。\r\n\r\n追蹤王感謝您!',
    MESSAGE_FINANCE_CONTENT: '您的{0}{1}，{2}作業已成功。',
    HISTORY_NO_DATA: '查無裝置【{0}】於該時段之軌跡資料!'
  },
  ADMIN_LOGIN: {
    TITLE: '平台管理登入頁面',
    ACCOUNT: '帳號：',
    ACCOUNT_HINT: '請輸入管理帳號',
    ACCOUNT_CLIENT_HINT: '請輸入帳號/終端號碼',
    PASSWORD: '密碼：',
    PASSWORD_HINT: '請輸入密碼',
    BTN_LOGIN: '登入',
    FORGET_PASSWORD: '忘記密碼',
    EMAIL_HINT: '請輸入帳號Email',
    FORGET_PASSWORD_HINT: '若無綁定email,請洽詢售後客服'
  },
  TOP_TABS: {
    ACCOUNT: '帳號管理',
    PERMISSION: '權限管理',
    DEVICE: '終端管理',
    FLEET: '車隊管理',
    CUSTOMER: '客服管理',
    PLAN: '續約加值',
    VIP_PLAN: '加值服務',
    PLAN_SETTING: '加值項目設定',
    STATISTICS: '數據統計',
    REPORT: '問題回報',
    FINANCE: '財務報表',
    ANNOUNCEMENT: '公告管理',
    SYSTEM_LOG: '系統記錄',
    SETTING: '基礎設置',
    MONITOR: '綜合監控',
    MULTI_MONITOR: '多車監控',
    REMOTE: '遠程指令',
    WARNING: '車輛告警',
    HISTORY: '歷史軌跡',
    SERVICE_EXPIRE: '服務過期',
    MILEAGE: '里程保養',
    TELECOM_SERVICE: '電信流量儲值'
  },
  SIDE_MENU: {
    VIP_PLAN: ['續約服務管理', 'VIP加值服務管理', '折價卷管理'],
    ANNOUNCEMENT: ['公告清單', '訊息清單']
  },
  ADMIN_ACCOUNT: {
    TABLES: ['#', '帳號名稱', '暱稱', '權限', '狀態', '最後登入時間', '動作'],
    MODAL_TITLE: '系統帳號',
    MODAL_CONTENT: ['帳號名稱', '暱稱', '密碼', '密碼確認', '權限', '狀態'],
    NEVER_LOGIN: '尚未登入'
  },
  ADMIN_PERMISSION: {
    TABLES: ['#', '權限名稱', '動作'],
    MODAL_TITLE: '權限項目',
    MODAL_CONTENT: ['權限名稱', '權限功能']
  },
  ADMIN_DEVICE: {
    SEARCH_NUMBER: '終端號碼',
    SEARCH_ZONE: '終端區間',
    TABLES: ['#', '終端號碼', '劃撥車隊', '方案', '狀態', '圖霸地址次數', '服務到期時間', '建立時間', '動作'],
    PLAN: ['無方案', '方案A', '方案B', '方案C', '方案D', '方案E', '方案F', '方案G', '方案H', '方案I', '方案J'],
    NONE_PLAN: '',
    STATUS: ['未劃撥', '已劃撥'],
    MODAL_VIEW_TITLE: '終端資訊',
    MODAL_ADD_TITLE: '新增終端號碼',
    MODAL_ALLOCATE_TITLE: '單筆劃撥',
    MODAL_VIEW_CONTENT: ['終端號碼', '車主姓名', '車輛名稱', 'SIM卡號', '手機號碼', '密碼', '車電', '韌體版本', 'ESIM ID', '加值服務', '服務狀態', '圖霸地址剩餘次數', 'Email'],
    MODAL_ADD_CONTENT: ['起始終端號碼', '新增筆數', '預設方案'],
    MODAL_ALLOCATE_CONTENT: ['終端號碼', '更新服務到期時間', '方案', '劃撥車隊', '服務到期時間'],
    MODAL_IMPORT_CHECK_HINT: '檢查資料中',
    MODAL_ALLOCATE_HINT: '請注意，該終端已經劃撥使用中!!!'
  },
  ADMIN_FLEET: {
    TABLES: ['#', '帳號', '名稱', '電話', 'Email', '車輛數', '最後登入時間', '動作'],
    SUB_TABLES: ['#', '帳號', '子車隊名稱', '車輛數', '動作'],
    MODAL_TITLE: '車隊帳號',
    MODAL_MAIN_FLEET: '主車隊帳號',
    MODAL_FLEET_CONTENT: ['帳號', '名稱', '聯絡人', '電話', '地址', 'Email', '密碼', '權限'],
    MODAL_SUB_FLEET_TITLE: '子車隊帳號',
    MODAL_SUB_FLEET_CONTENT: ['帳號', '密碼', '子車隊名稱', '權限'],
    SUB_PERMISSION: ['編輯車輛資訊', '車隊管理', '下發指令'],
    AUTO_PASSWORD: '系統自動建立',
    RESET_PASSWORD: 'Email重設',
    FLEET_SUB_LIST: '子車隊清單',
    FLEET_DEVICE_LIST: '終端清單'
  },
  ADMIN_SYSTEM_LOG: {
    TABLES: ['#', '時間', '連線IP', '帳號Id', '動作', '狀態'],
    ACTION_STATUS: ['', '成功', '權限不足', '失敗']
  },
  ADMIN_PLAN: {
    TABLES: ['#', '終端號碼', '車輛名稱', '附屬車隊', '狀態', '服務到期時間', '加值到期時間', '動作', '加值地址剩餘次數'],
    MODAL_TITLE: '校正服務',
    MODAL_MODIFY_SERVICE_TYPE: '校正服務項目',
    EXPIRED_DAY: '服務到期日',
    MODIFY_SERVICE_EXPIRED: '校正服務到期日',
    VIP_SERVICE_DAY: '加值到期日',
    EXPIRED: '服務續約(天)',
    VALUE_SERVICE: '加值服務(天)',
    SEARCH_HOLDER: '請輸入 終端編號/車輛名稱',
    EXTRA_ADDRESS_TIMES: '加值地址次數',
    IMPORT_MODIFY_DATA: '批量校正',
    EXPIRED_NEXT_YEAR: '+年',
    OVER_ONE_YEAR_WARNING_HINT: '您所選擇的到期日已超出該設備服務到期日一年，請確認是否有誤!!!'
  },
  ADMIN_VIP_PLAN: {
    SERVICE_TABLES: ['#', '方案', '項目', '價格', '時間(天)', '超時追加設定費', '狀態', '動作', '新增地址次數'],
    COUPON_TABLES: ['序號', '折價卷號碼', '折抵費用', '狀態', '訂單號碼', '終端號碼', '使用時間', '建立時間', '動作'],
    COUPON_STATUS: ['未發送', '已發送', '已使用'],
    COUPON_ACTION_BUTTON: ['發送', '使用', '已使用'],
    MODAL_SERVICE_TITLE: '續約項目',
    MODAL_VIP_PLAN_TITLE: 'VIP加值服務項目',
    MODAL_COUPON_TITLE: '新增折價卷',
    MODAL_COUPON_USE_TITLE: '使用折價卷',
    MODAL_COUPON_CONTENT: ['新增筆數', '折抵金額'],
    MODAL_SERVICE_CONTENT: ['項目名稱', '金額', '加值天數', '方案', '超時設定費用', '超時天數', '說明', '狀態', '解鎖方案', '加值圖霸地址次數', '會計項目'],
    MODAL_CONFIRM_CONTENT: '是否發送 {0} 折價卷?'
  },
  ADMIN_ANNOUNCEMENT: {
    ANNOUNCEMENT_TABLES: ['#', '發送者', '內容', '發送時間', '建立時間', '動作'],
    MESSAGE_TABLES: ['#', '發送者', '內容', '接收對象', '發送時間', '建立時間', '動作'],
    MODAL_ANNOUNCEMENT_CONTENT: ['發送時間', '發送內容'],
    MODAL_MESSAGE_RECIVER_TABLES: ['#', '接收對象', '是否包含車輛', '動作'],
    MODAL_SEARCH_OPTION: ['車隊', '終端號碼'],
    MODAL_SHOW_TITLE: ['公告內容', '訊息內容'],
    MODAL_DELETE_CONTENT: '確認是否要刪除第{0}筆公告資料?'
  },
  ADMIN_REPORT: {
    REPORT_SEARCH_OPTIONS: ['帳號名稱', '聯絡人', '聯絡方式'],
    REPORT_TABLES: ['#', '帳號名稱', '聯絡人', '聯絡方式', '問題類別', '狀態', '時間', '動作'],
    PROCESS_STATUS: ['未處理', '已聯繫', '已結案'],
    MODAL_TITLE: '問題回報內容',
    REPORT_TYPE: ['操作問題', '車機問題', '其他'],
    REPORT_METHOD_PLACEHOLDER: '請輸入連絡電話或Email'
  },
  ADMIN_FINANCE: {
    TABLES: ['#', '訂單日期', '終端帳號', '服務名稱', '付款金額', '藍新金流交易序號', '訂單狀態', '付款時間', '付款方式', '付款註記', '發票類型', '發票號碼', '發票註記', '發票時間', '動作', '支付狀態'],
    MODIFY_TABLES: ['#', '終端帳號', '校正服務到期日', '加值電信服務(天)', '加值地址次數', '操作帳號', '操作時間'],
    SEARCH_OPTIONS: ['時間區間', '藍新金流交易序號', '終端帳號', '發票號碼'],
    SEARCH_PAYMENT_OPTIONS: ['尚未支付', '支付完成', '支付失敗', '訂單退款'],
    PAYMENT_STATUS: ['尚未支付', '授權完成', '授權失敗', '已取消授權', '請款申請中', '請款處理中', '已請款', '退款申請中', '退款處理中', '已退款'],
    ORDER_STATUS: ['', '等待支付', '已支付交付中', '已完成', '支付失敗已關閉', '取消作業申請中', '退還商品中', '退還商品完成', '退款作業中', '退款完成', '退票作業中', '已取消'],
    INVOICE_TYPE: ['個人', '公司', '捐贈'],
    INVOICE_NOTE: ['載具條碼', '統一編號', '捐贈碼'],
    PLAN_TYPE: ['', '續約服務', '加值服務'],
    EXPORT_NAME: '昇豪對帳資訊',
    EXPORT_TITLE: ['訂單日期', '藍新金流交易序號', '機器號碼', '購買項目', '購買方案', '開始(日期/次)', '結束(日期/次)', '購買金額', '優惠抵扣金額', '實際付款金額', '付款方式', '付款日期', '發票號碼', '發票日期'],
    PAYMENT_NOTE: ['後四碼'],
    ACTION_TITLE: ['退款', '作廢', '取消訂單'],
    MODAL_TITLE: ['交易取消/退款', '發票作廢/折讓'],
    MODAL_PAYMENT_TYPE: ['『人工手動加值』', '『藍新金流交易序號』'],
    MODAL_REFUND_CONTENT: '確認是否要將{0} \r\n【{1}】\r\n交易金額【{2}】 \r\n進行取消 或 退款 的動作?',
    MODAL_ABORT_CONTENT: '確認是否要將\r\n發票號碼 【{0}】\r\n進行作廢 或 折讓 的動作?',
    PAYMENT_TYPE: ['全部', '信用卡', '人工儲值', '超商支付'],
    RECORD_DATA_TYPE: '查詢項目'
  },
  ADMIN_STATISTICS: {
    SIDE_MENU: ['統計總覽', '里程統計', '告警統計', '超速統計', '離線統計', '運行統計'],
    SIDE_SUB_MENU: ['單日', '單月', '日區間'],
    SIDE_SUB_EXTRA: ['離線終端統計', '在線狀態統計'],
    OVERSPEED: '限速',
    OFFLINE_END_TIME: '截止時間',
    OFFLINE_DAYS: '離線時長',
    STATISTICS_SLEEPING_DEVICE: '統計休眠設備'
  },
  ADMIN_SETTING: {
    DEFAULT_SETTING_TITLE: '基本設定',
    MAP_SETTING_TITLE: '地圖設定',
    DEFAULT_SETTINGS: ['標記點轉換功能', 'APP車輛地址功能', '超速報表統計', '離線報表統計', '折價卷功能', '畫面更新頻率(秒)'],
    MAP_SETTINGS: ['經緯度查詢地址', '(谷哥)地址查詢經緯度', 'Google圖資上限'],
    MAP_SUB_SETTING: ['每日總上限', '每月上限', 'VIP每日上限', 'VIP每月上限']
  },
  MESSAGE: {
    DELETE_SUB_FLEET: '是否要刪除子車隊帳號: ',
    DELETE_MARKER_POINT: '是否要刪除 [{0}] 的 [{1}] 標記點?',
    DELETE_ELECTRONIC_FENCE: '是否要刪除 [{0}] 的 [{1}] 電子圍籬?',
    DELETE_MILEAGE_MAINTANCE: '是否要刪除 [{0}] 的 [{1}] 保養項目?',
    STOP_DEVICE_STATUS: '是否要將 [{0}] 等的終端帳號停機?',
    ERROR_TIME_PICKER: '時間欄位內容格式錯誤, 請輸入正確格式(hh:mm)',
    ERROR_PASSWORD: '請確認密碼是否有誤',
    MESSAGE_SEARCH_DATA_EMPTY: '搜尋條件查無資料，請重新查詢'
  },
  TABLES: {
    ALL: ['#', '車牌號', '里程數(km)', '超速次數', '告警次數', '離線時長'],
    MILEAGE: ['#', '車牌號', '日期', '時段', '里程數(km)', '軌跡'],
    WARNING: ['#', '車牌號', '日期', '時間', '經度', '緯度', '告警訊息', '告警次數', '軌跡'],
    OVERSPEED: ['#', '車牌號', '日期', '時段', '超速次數', '軌跡'],
    OFFLINE: ['#', '車牌號', '日期', '離線開始時間', '離線結束時間', '離線時長', '軌跡'],
    ALERT: ['車牌號', 'GPS時間', '定位方式', '速度', '經緯度', '車輛狀態', '告警訊息', '告警次數', '終端號碼'],
    TRACKER: ['#', 'GPS時間', '定位方式', '地址', '標記名稱', '經緯度', '速度', '車輛狀態', '告警訊息', '停車時長', '電量'],
    PARKING: ['#', 'GPS時間', '定位方式', '地址', '經緯度', '停車時長', '車輛狀態', '告警訊息'],
    HISTORY_WARNING: ['#', 'GPS時間', '定位方式', '地址', '經緯度', '告警訊息', '車輛狀態', '速度'],
    OFFLINE_DEVICE: ['#', '車牌號', '終端號碼', 'SIM卡號', '車主電話', '附屬車隊', '服務狀態', '最後上線時間', '離線時長', '車輛狀態'],
    ONLINE_STATUS_TYPE: ['在線', '未使用', '休眠', '離線小於12小時', '離線大於12小時至90天', '未上線達90天', '未上線達180天']
  },
  HISTORY: {
    CONTROL_TOTAL: '軌跡總數',
    CONTROL_POS: '位置',
    CONTROL_MILEAGE: '總里程',
    CONTROL_STEP: '回放進度',
    CONTROL_SPEED: '回放速度',
    CONTROL_START: '開始回放',
    CONTROL_STOP: '停止回放',
    CONTROL_SHOW_PARKING: '顯示停車點',
    TABLES: ['軌跡報表', '停車報表', '告警報表'],
    CONTROL_HINT: '僅有基地台定位有軌跡資訊，請取消勾選【過濾基地台定位】來查看軌跡資訊!'
  },
  ALERT: {
    REMOVE_SINGLE: '解除報警',
    REMOVE_ALL: '解除全部',
    ALERT_TITLE: '您有[{0}]輛車發生報警,共產生[{1}]報警',
    ALERT_TYPE: ['', '震動報警']
  },
  REMOTE: {
    TABS: ['查詢參數', '設置參數', '遠程控制', '設備回傳'],
    TITLE: '參數名稱',
    VALUE: '參數值',
    CONTROL: ['重啟終端', '開啟震動告警', '關閉震動告警', '開啟聲音告警', '關閉聲音告警'],
    TABLES: ['時間', '終端號碼', '遠程指令', '參數名稱', '參數內容', '狀態'],
    LEFT_DEVICE_LIST: '目標車輛',
    MODAL_FREQ_CONTENT: '請注意：\r\n\r\n1.定位更新速度「快」將會更耗電。\r\n2.若每天運行超過13個小時以上，請勿調整此設定。'
  },
  RECORD: {
    TITLE: '錄音功能',
    CONTROL: ['關閉錄音功能', '開啟錄音功能'],
    REMOTE_MODEL_CONTENT: ' 發送請求中',
    TABLES: ['#', '終端號碼', '錄音時間', '獲取錄音資訊時間', '動作'],
    BUTTON: ['請求上傳錄音檔', '錄音資料已失效', '下載錄音檔', '錄音檔上傳中'],
    MODAL_CONTENT: ['已請求 {0} 確認 {1} 錄音檔是否存在中, 若存在請等待設備上傳錄音檔']
  },
  ADMIN_MONITOR: {
    DEVICE_TABLES: ['車牌號', 'GPS時間', '定位類別', '速度', '經緯度', '地址', '車輛狀態', '告警訊息', '電池容量', 'GPS訊號', 'GSM訊號', '電壓', '到期日']
  },
  MONITOR: {
    TABS: ['車輛列表', '電子圍籬', '標記點'],
    MARKED_POINT_TABLES: ['#', '作用對象', '對象名稱', '座標', '備註', '動作'],
    ELE_FENCE: ['#', '作用對象', '對象名稱', '進入告警', '離開告警', '座標', '備註', '動作'],
    LOCATE_TYPE: ['基地台定位', '衛星定位'],
    DEVICE_STATUS_TYPE: ['休眠', '靜止', '運動', '無訊號'],
    PROTECTED_TYPE: ['無設防', '已設防(震動)', '已設防(聲音)', '已設防(綜合)'],
    MODAL_ELEFENCE: '電子圍籬對象',
    MODAL_MARKER_POINT: '標記點對象',
    ALARM_TYPE: '告警類型',
    ELE_TARGET_TYPE: ['住家', '公司', '自定義'],
    MODAL_MARKER_TYPE: '標記方式',
    MARKER_POINT_TYPE: ['地址轉換GPS', '地圖點選'],
    MARKER_POINT: '標記點',
    ADDREDS_TO_GPS_HINT: '請輸入定位點地址',
    DRAW_MAP_HINT: '請點選[繪製]按鈕，在頁面中進行繪製',
    PARKING_TIME: '停車:{0}',
    DRAW_ELECTONIC_FENCE: '繪製電子圍籬',
    DRAW_MARKER_POINT: '繪製標記點',
    DRAW_TYPE_TITLE: '繪製方式',
    DRAW_TYPE: ['同心圓', '多點設定'],
    COORS: ['經度', '緯度'],
    IS_SLEEPING: '休眠'
  },
  TABLE_FOOTER: {
    FIRST: '首頁',
    PREVIOUS: '上一頁',
    NEXT: '下一頁',
    END: '末頁',
    TOTAL_PAGE: '共{0}頁',
    TOTAL_COUNT: '共{0}筆資料',
    EXPORT: '匯出'
  },
  LEFT_LIST: {
    TITLE: '車輛列表 (更多功能請按右鍵)'
  },
  SERVICE_EXPIRE: {
    TITLE: '您有[{0}]輛車服務到期或即將到期，請立即續約，以免影響您的正常使用',
    TABLES: ['#', '終端編號', '車輛名稱', '服務狀態', '服務到期時間', '剩餘時間', '動作'],
    RENEW: '立即續費',
    MODAL_TITLE: '續費付款資訊',
    MODAL_OPTION: '續約項目',
    MODAL_PRICE: '續約費用',
    MODAL_COUPON: '優惠卷',
    MODAL_TOTAL: '合計'
  },
  MAINTENANCE: {
    TABLES: ['#', '車輛名稱', '當前里程(km)', '保養里程(km)', '保養項目', '內容', '動作']
  },
  VIP_SERVICE: {
    TABLES: ['#', '項目', '價格', '動作'],
    ADD_SERVICE: '立即加值',
    MODAL_TITLE: '加值服務付款資訊',
    MODAL_INVOICE: ['電子郵件', '發票資訊', '說明'],
    MODAL_INVOICE_TYPE: ['個人用統一發票', '公司用統一發票', '發票捐贈'],
    INVOICE_DEVICE: '手機載具',
    INVOICE_TAX: '統一編號',
    INVOICE_EMAIL: ['同上', '自定義'],
    PAYMENT_HINT: '本平台不提供紙本發票寄送，電子發票將由昇豪科技代為開立',
    EMAIL_HINT: '請填寫Email信箱，收取電子發票',
    INVOICE_DEVICE_HINT: '請輸入"/"開頭之條碼共8碼',
    INVOICE_TAX_HINT: '請輸入8碼統編數字',
    EMAIL_CLONE: '同上',
    SERVICE_CATEGORY: '服務項目'
  },
  MILEAGE_MAINTANCE: {
    TABLES: ['#', '車輛名稱', '當前里程(km)', '保養里程(km)', '保養項目', '內容', '動作'],
    MODAL_TITLE: '里程保養項目'
  },
  COUPON: {
    IS_USED: '該序號已被使用',
    CAN_NOT_USED: '該序號無法使用',
    SERIAL_ERROR: '輸入序號有誤'
  },
  CONTEXT_MENU: {
    FLEET: ['車隊訊息', '批量指令', '數據統計', '空間對象', '建立子車隊'],
    DEVICE: ['顯示軌跡', '鎖定監控', '車輛訊息', '歷史軌跡', '下發指令', '數據統計', '空間對象', '劃撥車隊'],
    SUN_FLEET_REMOTE: ['僅本車隊車輛', '下屬所有車輛'],
    MODAL_DEVICE_CONTENT: ['設備號碼', '車輛名稱', '車主姓名', '手機號碼', '密碼', 'SIM卡號', '到期日期', '車輛圖標', 'Email'],
    REMOTE_MAIN: ['立即定位', '設防撤防', '更多指令'],
    REMOTE_SUB_MENU: ['設防', '撤防'],
    CAR_ICON: ['汽車', '機車', '腳踏車', '貨車', '挖土機', '船', '行人']
  },
  MULTI_MONITOR: {
    CONTROL_TITLE: ['地標顯示', '衛星地圖', '地址突顯'],
    BATTERY: '電量',
    ZOOM_BAR: '比例尺',
    LAST_UPDATE_TIME: '回傳時間',
    MULTI_MONITOR_HINT: '請於左側選擇車輛，最多可同時監控9輛'
  }
}
