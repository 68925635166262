<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="row justify-content-center">
          <img class="d-flex col-3 icon-alert" :src="icon.alert">
          <h4 class="d-flex col-3 modal-title">{{ errTitle }}</h4>
        </div>
        <hr/>
        <label class="message-content">{{ errData.errMsg }}</label>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" @click="onConfirmClickEvent">{{ t('GENERAL.CLOSE') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useCookies } from 'vue3-cookies'
import { inject, reactive, ref } from '@vue/runtime-core'

export default {
  props: {
    isAdminPage: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    errorData: {
      type: Object,
      required: false,
      default: function () {
        return {
          code: -1,
          errMsg: ''
        }
      }
    }
  },
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const router = useRouter()
    const configData = inject('configData')
    const tools = inject('tools')
    const { cookies } = useCookies()

    const icon = {
      alert: require('../../assets/icon/alert-danger.svg')
    }

    const showModal = ref(false)
    const isAdminPage = ref(false)
    const errData = reactive({
      code: -1,
      errMsg: ''
    })

    const errTitle = ref('')

    function showModalWithData (isAdmin, title, err) {
      Object.assign(errData, err)
      isAdminPage.value = isAdmin
      errTitle.value = tools.isEmpty(title) ? t('MODAL.ERROR_TITLE') : title
      showModal.value = true
    }

    function onConfirmClickEvent () {
      switch (errData.code) {
        case -8:
        case -9:
        case -10:
        case -11:
          cookies.remove(configData.cookieName)
          router.push({ path: isAdminPage.value ? '/admin/login' : '/' })
          break
      }
      emit('modalCallback')
      showModal.value = false
    }

    return {
      t,
      icon,
      errTitle,
      errData,
      showModal,
      showModalWithData,
      onConfirmClickEvent
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-overlay {
  .icon-alert {
    width: 54px
    height: 54px
  }
  .modal-title {
    color: red
    margin-top: 1rem
  }
  .message-content {
    margin: 1rem 0.3rem 1rem 0.3rem
  }
}
</style>
