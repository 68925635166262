<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-dialog">
      <div class="modal-content">
        <h4 class="modal-title">{{ modalTitle }}</h4>
        <hr/>
        <form class="row g-3 p-3">
          <div class="form-group row mt-2">
            <label class="fs-4 mx-3" style="white-space: pre-line; text-align: center;" :style="'color:' + contentFontColor">{{ modalContent }}</label>
          </div>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onCancelClickEvent">{{ t('GENERAL.CLOSE') }}</button>
          <button type="button" class="btn btn-primary" @click="onSubmitClickEvent">{{ t('GENERAL.OK') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
export default {
  emits: ['modalCallback'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const showModal = ref(false)
    const modalTitle = ref('')
    const modalContent = ref('')
    const modalData = reactive({})
    const contentFontColor = ref('#000000')

    function showModalConfirm (title, content, data, color = '#000000') {
      modalTitle.value = title
      contentFontColor.value = color
      modalContent.value = content
      Object.assign(modalData, data)
      showModal.value = true
    }

    function onCancelClickEvent () {
      showModal.value = false
    }

    function onSubmitClickEvent () {
      emit('modalCallback', modalData)
      showModal.value = false
    }

    return {
      t,
      showModal,
      modalTitle,
      modalContent,
      modalData,
      contentFontColor,
      showModalConfirm,
      onSubmitClickEvent,
      onCancelClickEvent
    }
  }
}
</script>
